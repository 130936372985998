<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <new-table v-if="showNewModal" />
        <edit-table v-if="showEditModal" />
        <v-card>
          <v-card-title>
            <v-list-item two-line>
              <v-list-item>
                <v-list-item-title>
                  {{ $vuetify.lang.t('$vuetify.menu.tables_list') }}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-btn
                    class="mb-2"
                    color="primary"
                    @click="showNewBox"
                  >
                    <v-icon
                      v-hotkey="keymap"
                    >mdi-plus</v-icon>
                    {{ $vuetify.lang.t('$vuetify.actions.new') }}
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(shop,i) in tables"
                  :key="i"
                  cols="12"
                  md="6"
                >
                  <v-card>
                    <v-card-actions>
                      <v-list-item two-line>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $vuetify.lang.t('$vuetify.menu.shop') }}: {{ shop['name'] }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item>
                    </v-card-actions>
                    <v-divider />
                    <v-list class="transparent">
                      <app-data-table
                        csv-filename="Tables"
                        :headers="getTableColumns"
                        :view-new-button="false"
                        :view-edit-button="true"
                        :view-tour-button="false"
                        :view-show-filter="false"
                        :is-loading="isTableLoading"
                        :items="shop['tables']"
                        :manager="'tables'"
                        :sort-by="['name']"
                        :sort-desc="[false, true]"
                        multi-sort
                        @create-row="toogleNewModal(true)"
                        @edit-row="openEditModal($event)"
                        @delete-row="deleteTablesHandler($event)"
                      >
                        <template v-slot:[`item.state`]="{ item }">
                          <span>{{ item.state === 'open' ? $vuetify.lang.t('$vuetify.tables_open') : $vuetify.lang.t('$vuetify.tables_close') }}</span>
                        </template>
                      </app-data-table>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NewTable from './NewTable'
import EditTable from './EditTable'
export default {
  name: 'ListTable',
  components: {
    NewTable,
    EditTable
  },
  data () {
    return {
      labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
      time: 0,
      forecast: [
        { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
        { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
        { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' }
      ],
      search: ''
    }
  },
  computed: {
    keymap () {
      return {}
    },
    ...mapState('tables', [
      'opencloseTables',
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'tables',
      'isTableLoading'
    ]),
    ...mapState('auth', ['cantBox', 'company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'state'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getTables()
  },
  methods: {
    ...mapActions('tables', ['toogleNewModal', 'openEditModal', 'openCloseModal', 'getTables', 'getTable', 'deleteTable', 'getOperations']),
    showNewBox () {
      if (!this.getSubscription) {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
            text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            ),
            confirmButtonColor: 'red'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'my_subscription' })
            }
          })
      } else { this.toogleNewModal(true) }
    },
    async showTableOperation ($event) {
      await this.getOperations({ table_id: $event })
      await this.$router.push({ name: 'tables_operations' })
    },
    openTable ($event) {
      this.openCloseModal($event)
    },
    showMessage (title) {
      this.$Swal.fire({
        title: title,
        text: this.$vuetify.lang.t(
          '$vuetify.messages.warning_digital_table'
        ),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: this.$vuetify.lang.t(
          '$vuetify.actions.accept'
        ),
        confirmButtonColor: 'red'
      })
    },
    deleteTablesHandler ($event) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.table')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteTable($event)
        })
    }
  }
}
</script>

<style scoped>

</style>
